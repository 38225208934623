<template>
    <kinesis-container>
        <section id="ca-bouge" class="page-section page-section-custom saintOuen-6-b">
            <div class="saintOuen-6">
                <div class="img-left">
                    <div class="desk-img">
                        <figure><kinesis-element :strength="5"><img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-1.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-1-retina.jpg 2x"' alt="desk-imgBouge-1"></kinesis-element></figure>
                        <figure><kinesis-element :strength="15"><img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-2.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-2-retina.jpg 2x"' alt="desk-imgBouge-2"></kinesis-element></figure>
                    </div>
                    <div class="mob-img">
                        <div class="mob-img-ctn">
                            <figure><img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-1.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-1.jpg"' alt="mob-imgBouge-1"></figure>
                            <figure><img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-3.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-3.jpg"' alt="mob-imgBouge-3"></figure>
                        </div>
                    </div>
                </div>
                <div class="sp-ParentBloc">
                    <div class="sp-bloc">
                        <h4 class="title-h4 anim-slide-left">{{$t('dataBouge')['0']}}</h4>
                        <div class="sp-title">
                            <h2 class="title-h2 anim-txt-focus">{{$t('dataBouge')['1']}}</h2>
                            <p class="anim-slide-right">
                                <strong>{{$t('dataBouge')['2']}}</strong> {{$t('dataBouge')['3']}}
                            </p>
                            <p class="anim-slide-right">{{$t('dataBouge')['4']}} <strong>{{$t('dataBouge')['5']}}</strong>, {{$t('dataBouge')['6']}}</p>
                        </div>
                    </div>
                </div>
                <div class="img-right">
                    <div class="desk-img">
                        <figure><kinesis-element :strength="5"><img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-3.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-3-retina.jpg 2x"' alt="desk-imgBouge-3"></kinesis-element></figure>
                        <figure><kinesis-element :strength="25"><img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-4.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-4-retina.jpg 2x"' alt="desk-imgBouge-4"></kinesis-element></figure>
                    </div>
                    <div class="mob-img">
                        <div class="mob-img-ctn">
                            <figure><img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-2.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-2.jpg"' alt="mob-imgBouge-2"></figure>
                            <figure><img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-4.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-4.jpg"' alt="mob-imgBouge-4"></figure>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </kinesis-container>
</template>

<script>
    import { KinesisContainer, KinesisElement} from 'vue-kinesis'
    export default {
        name: 'SaintOuen6',
        components:{
            KinesisContainer,
            KinesisElement
        },
    }
</script>
